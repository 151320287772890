import React from 'react'
import PropTypes from 'prop-types'
import { Link, graphql } from 'gatsby'

import Layout from '../components/Layout'
import BlogRoll from '../components/BlogRoll'


import IcoPDF from '../../static/img/icons/ico_pdf.png'


import ZaleceniaMontazowe from '../../static/pliki/zalecenia_montazowe.pdf'
import Instrukcja_lacznik_lat_dachowych from '../../static/pliki/Instrukcja_lacznik_lat_dachowych.pdf'
import montaz_barierek_law_kominiarskich from '../../static/pliki/montaz_barierek_law_kominiarskich.pdf'
import Instrukcja_montazu_wspornikow_uniwersalnych_na_rabek_stojacy from '../../static/pliki/Instrukcja_montazu_wspornikow_uniwersalnych_na_rabek_stojacy.pdf'
import Montaz_wspornika_lawy_stopnia_typ_C from '../../static/pliki/Montaz_wspornika_lawy_stopnia_typ_C.pdf'
import Montaz_wspornika_plotka_rur_typ_C from '../../static/pliki/Montaz_wspornika_plotka_rur_typ_C.pdf'
import wsporniki_rur_relingowych from '../../static/pliki/wsporniki_rur_relingowych.pdf'
import instrukcja_montazu_hakow_bezpieczenstwa from '../../static/pliki/instrukcja_montazu_hakow_bezpieczenstwa.pdf'
import montaz_rozbijaczy_blachodachowki from '../../static/pliki/montaz_rozbijaczy_blachodachowki.pdf'
import Montaz_wspornikow_dla_blachodachowek from '../../static/pliki/Montaz_wspornikow_dla_blachodachowek.pdf'
import Wsporniki_na_dachowke_Orea from '../../static/pliki/Wsporniki_na_dachowke_Orea.pdf'
import Montaz_wspornikow_Decra from '../../static/pliki/Montaz_wspornikow_Decra.pdf'


import "../components/layout.css"

export const InstrukcjePageTemplate = ({  intro }) => (
         <div>
           <div>
             <div></div>
    </div>
           <section className="section section--gradient antyPadding">
             <div className="container">
        <div className="section AboutP antyPadding">
                 <div className="columns">
                   <div className="column is-10 is-offset-1 StoPro">
                     <div className="content">
                       <div className="columns">
                  <div className="column is-12">
                  </div>
                       </div>
                <div className=""><h3 className="has-text-weight-semibold is-size-2 headingCSS5">
                  {intro.heading}<hr className="hr"></hr>
                </h3>
                  <div className="grid-containerCertyfikaty">
                    <div className="lewyCertyfikaty"><li className="fl">
                      <a href={ZaleceniaMontazowe} target="_blank"  rel="noopener noreferrer"><img alt=" " src={IcoPDF} alt="" className="fvam" /></a>				<a href={ZaleceniaMontazowe} className="fvam" target="_blank"  rel="noopener noreferrer">Zalecenia montażowe</a>			</li>
                      <li className="fl">
                        <a href={Instrukcja_lacznik_lat_dachowych} target="_blank"  rel="noopener noreferrer"><img alt=" " src={IcoPDF} alt="" className="fvam" /></a>				<a href={Instrukcja_lacznik_lat_dachowych} className="fvam" target="_blank"  rel="noopener noreferrer">Instrukcja łącznik łat dachowych</a>			</li>
                      <li className="fl">
                        <a href={montaz_barierek_law_kominiarskich} target="_blank"  rel="noopener noreferrer"><img alt=" " src={IcoPDF} alt="" className="fvam" /></a>				<a href={montaz_barierek_law_kominiarskich} className="fvam" target="_blank"  rel="noopener noreferrer">Montaż barierek ław kominiarskich</a>			</li>
                      <li className="fl">
                        <a href={Instrukcja_montazu_wspornikow_uniwersalnych_na_rabek_stojacy} target="_blank"  rel="noopener noreferrer"><img alt=" " src={IcoPDF} alt="" className="fvam" /></a>				<a href={Instrukcja_montazu_wspornikow_uniwersalnych_na_rabek_stojacy} className="fvam" target="_blank"  rel="noopener noreferrer">Montaż wsporników na rąbkach stojących</a>			</li>
                      <li className="fl">
                        <a href={Montaz_wspornika_lawy_stopnia_typ_C} target="_blank"  rel="noopener noreferrer"><img alt=" " src={IcoPDF} alt="" className="fvam" /></a>				<a href={Montaz_wspornika_lawy_stopnia_typ_C} className="fvam" target="_blank"  rel="noopener noreferrer">Montaż wspornika ławy stopnia typ C (D)</a>			</li>
                      <li className="fl">
                        <a href={Montaz_wspornika_plotka_rur_typ_C} target="_blank"  rel="noopener noreferrer"><img alt=" " src={IcoPDF} alt="" className="fvam" /></a>				<a href={Montaz_wspornika_plotka_rur_typ_C} className="fvam" target="_blank"  rel="noopener noreferrer">Montaż wspornika płotka rury typ C (D)</a>			</li></div>
                    <div className="prawyCertyfikaty"><li className="fl">
                      <a href={wsporniki_rur_relingowych} target="_blank"  rel="noopener noreferrer"><img alt=" " src={IcoPDF} alt="" className="fvam" /></a>				<a href={wsporniki_rur_relingowych} className="fvam" target="_blank"  rel="noopener noreferrer">Montaż wsporników rur relingowych</a>			</li>
                      <li className="fl">
                        <a href={instrukcja_montazu_hakow_bezpieczenstwa} target="_blank"  rel="noopener noreferrer"><img alt=" " src={IcoPDF} alt="" className="fvam" /></a>				<a href={instrukcja_montazu_hakow_bezpieczenstwa} className="fvam" target="_blank"  rel="noopener noreferrer">Montaż haków bezpieczeństwa</a>			</li>
                      <li className="fl">
                        <a href={montaz_rozbijaczy_blachodachowki} target="_blank"  rel="noopener noreferrer"><img alt=" " src={IcoPDF} alt="" className="fvam" /></a>				<a href={montaz_rozbijaczy_blachodachowki} className="fvam" target="_blank"  rel="noopener noreferrer">Montaż rozbijaczy dla blachodachówki</a>			</li>
                      <li className="fl">
                        <a href={Montaz_wspornikow_dla_blachodachowek} target="_blank"  rel="noopener noreferrer"><img alt=" " src={IcoPDF} alt="" className="fvam" /></a>				<a href={Montaz_wspornikow_dla_blachodachowek} className="fvam" target="_blank"  rel="noopener noreferrer">Montaż wsporników dla blachodachówek</a>			</li>
                      <li className="fl">
                        <a href={Wsporniki_na_dachowke_Orea} target="_blank"  rel="noopener noreferrer"><img alt=" " src={IcoPDF} alt="" className="fvam" /></a>				<a href={Wsporniki_na_dachowke_Orea} className="fvam" target="_blank"  rel="noopener noreferrer">Relingi montaż kryteria</a>			</li>
                      <li className="fl">
                        <a href={Montaz_wspornikow_Decra} target="_blank"  rel="noopener noreferrer"><img alt=" " src={IcoPDF} alt="" className="fvam" /></a>				<a href={Montaz_wspornikow_Decra} className="fvam" target="_blank"  rel="noopener noreferrer">Montaż wsporników typu Decra na blachach</a>			</li></div>
                  </div>
                  <ul className="files-list">
                    <div className="clear"></div>
                  </ul> </div>
                       <div className="columns artykulyProd">
                         <div className="column is-12 has-text-centered">

                         </div>
                       </div>
                       <div className="column is-12 wpisy">
                         <h3 className="has-text-weight-semibold is-size-2">
                           Najnowsze wpisy:
                         </h3>
                         <BlogRoll />
                         <div className="column is-12 has-text-centered">
                           <Link className="btn" to="/blog">
                             Czytaj więcej
                           </Link>
                         </div>
                       </div>
                     </div>
                   </div>
                 </div>
               </div>
             </div>
           </section>
         </div>
       );

InstrukcjePageTemplate.propTypes = {
  image: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  heading: PropTypes.string,
  subheading: PropTypes.string,
  description: PropTypes.string,
  intro: PropTypes.shape({
    blurbs: PropTypes.array,
  }),
  gallery: PropTypes.shape({
    blurbs: PropTypes.array,
  }),
}

const InstrukcjePage = ({ data }) => {
  const { frontmatter } = data.markdownRemark

  return (
    <Layout>
      <InstrukcjePageTemplate
        image={frontmatter.image}
        heading={frontmatter.heading}
        subheading={frontmatter.subheading}
        description={frontmatter.description}
        intro={frontmatter.intro}
        gallery={frontmatter.gallery}
      />
    </Layout>
  )
}

InstrukcjePage.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.shape({
      frontmatter: PropTypes.object,
    }),
  }),
}

export default InstrukcjePage

export const pageQuery = graphql`
         query InstrukcjePageTemplate {
           markdownRemark(
             frontmatter: { templateKey: { eq: "instrukcje-page" } }
           ) {
             frontmatter {
               heading
               description
               subheading
               intro {
                 blurbs {
                   image {
                     childImageSharp {
                       fluid(maxWidth: 780, quality: 100) {
                         ...GatsbyImageSharpFluid
                       }
                     }
                   }
                   text
                 }

                 heading
                 description
               }
               gallery {
                 blurbs {
                   image {
                     childImageSharp {
                       fluid(maxWidth: 780, quality: 100) {
                         ...GatsbyImageSharpFluid
                       }
                     }
                   }

                 }

               }
             }
           }
         }
       `;
